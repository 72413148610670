import styled from 'styled-components';

export const Container = styled.footer`
  display: flex;
  height: var(--footer-height);
  background-color: var(--color-yellow);
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  height: 100%;
  width: 100%;
  margin: 0 20px;
  border-top: 1px solid var(--color-black);
  @media (max-width: 991px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0 10px;
    padding-top: 10px;
    margin: 0 10px;
  }
`;

export const Section = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
`;

export const Text = styled.p`
  font: var(--font12);
  letter-spacing: 0.3px;
  color: var(--color-black);
  text-wrap: nowrap;
  white-space: nowrap;
  @media (max-width: 991px) {
    font: var(--font13);
    letter-spacing: 0.33px;
    text-wrap: wrap;
    white-space: normal;
  }
`;

export const Button = styled.button`
  font: var(--font12);
  letter-spacing: 0.3px;
  color: var(--color-black);
  text-wrap: nowrap;
  white-space: nowrap;
  transition: opacity 0.3s;
  @media (hover) {
    &:hover {
      opacity: 0.5;
    }
  }
  @media (max-width: 991px) {
    font: var(--font13);
    letter-spacing: 0.33px;
  }
`;

export const Link = styled.a`
  font: var(--font12);
  letter-spacing: 0.3px;
  color: var(--color-black);
  text-wrap: nowrap;
  white-space: nowrap;
  transition: opacity 0.3s;
  text-decoration: none;
  @media (hover) {
    &:hover {
      opacity: 0.5;
    }
  }
  @media (max-width: 991px) {
    font: var(--font13);
    letter-spacing: 0.33px;
  }
`;
