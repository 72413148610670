import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.div).attrs(({ $isOpened }) => ({
  animate: { translateY: $isOpened ? '0%' : '150%', opacity: $isOpened ? 1 : 0 },
  transition: {
    translateY: { delay: $isOpened ? 0 : 0.5, duration: 0 },
    opacity: { delay: 0, duration: 0.5 },
  },
  initial: { translateY: '150%', opacity: 0 },
}))`
  display: flex;
  flex-direction: column;
  position: fixed;
  inset: 0;
  background-color: var(--color-black);
  z-index: 11;
  transform: translateY(150%);
  opacity: 0;
  overscroll-behavior: none;
  padding-top: 20px;
  @media (max-width: 991px) {
    padding-top: 0;
  }
`;

export const Button = styled(motion.button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  border-radius: 50%;
  background-color: rgba(var(--color-grey46-rgb), 0.6);
  color: var(--color-white);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  margin: 0 20px;
  transition: background-color 0.3s;
  & > svg {
    transform: rotate(45deg);
  }
  @media (hover) {
    &:hover {
      background-color: rgb(var(--color-grey46-rgb));
    }
  }
  @media (max-width: 991px) {
    position: fixed;
    inset: 0;
    margin: 10px;
    z-index: 1;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 40px);
  max-height: calc(100% - 40px);
  padding-top: 45px;
  @media (max-width: 991px) {
    height: 100%;
    max-height: 100%;
    padding-top: 60px;
    overflow: auto;
  }
`;
