export const THERAPIST = 'therapist';
export const SUBJECTS = 'subjects';
export const CONTACT = 'contact';
export const ID_PREFIX = 'scroll_section_';
export const THERAPIST_SECTION_ID = `${ID_PREFIX}${THERAPIST}`;
export const SUBJECTS_SECTION_ID = `${ID_PREFIX}${SUBJECTS}`;
export const CONTACT_SECTION_ID = `${ID_PREFIX}${CONTACT}`;

export const HEADER_ITEMS = [
  {
    index: 0,
    id: THERAPIST_SECTION_ID,
    hash: `#${THERAPIST}`,
    label: 'Therapeutin',
    ratio: 0.324,
    offsetRatio: 0,
  },
  {
    index: 1,
    id: SUBJECTS_SECTION_ID,
    hash: `#${SUBJECTS}`,
    label: 'Themen',
    ratio: 0.24,
    offsetRatio: 0.324,
  },
  {
    index: 2,
    id: CONTACT_SECTION_ID,
    hash: `#${CONTACT}`,
    label: 'Angebot/Kontakt',
    ratio: 0.436,
    offsetRatio: 0.564,
  },
];
