import styled from 'styled-components';
import { motion } from 'framer-motion';
import { path } from 'ramda';

import { HEADER_ITEMS } from '@/utils/consts';

export const Container = styled.header`
  align-self: center;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: var(--header-height);
  width: calc(100% - 20px);
  max-width: 410px;
  position: fixed;
  inset: var(--header-height) auto auto;
  border-radius: calc(var(--header-height) / 2);
  background-color: rgba(var(--color-greyF7-rgb), 0.7);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  z-index: 10;
  transform: translate3d(0, 0, 0);
  @media (max-width: 991px) {
    inset: 10px auto auto;
  }
`;

export const Thumb = styled(motion.div).attrs(({ $id }) => {
  const ratio = path([$id, 'ratio'], HEADER_ITEMS);
  const offsetRatio = path([$id, 'offsetRatio'], HEADER_ITEMS);

  return {
    animate: {
      opacity: HEADER_ITEMS[$id] ? 1 : 0,
      left: offsetRatio ? `calc(${offsetRatio * 100}% + 2px)` : 2,
      width: ratio ? `calc(${ratio * 100}% - 4px)` : 4,
    },
    transition: { duration: 0.3 },
    initial: { opacity: 0, left: 2, width: 0 },
  };
})`
  position: absolute;
  inset: 2px auto 2px 2px;
  border-radius: calc((var(--header-height) - 4px) / 2);
  background-color: var(--color-greyF7);
  box-shadow: 0px 0px 3px var(--color-greyAC);
`;

export const Button = styled(motion.button)`
  width: ${({ $ratio }) => $ratio * 100}%;
  min-width: ${({ $ratio }) => $ratio * 100}%;
  max-width: ${({ $ratio }) => $ratio * 100}%;
  height: 100%;
  font: var(--font14);
  color: var(--color-black);
  text-wrap: nowrap;
  white-space: nowrap;
  user-select: none;
  z-index: 1;
`;
