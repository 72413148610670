import React, { useCallback, Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { useAsyncState } from '@/utils/hooks';
import Portal from '@/components/Portal';

import Popup from './Popup';
import { Container, Wrapper, Section, Text, Button, Link } from './styles';

const Footer = () => {
  const { dataJson } = useStaticQuery(graphql`
    query {
      dataJson(jsonId: { eq: "footer" }) {
        texts
        links {
          id
          type
          name
          value
        }
      }
    }
  `);
  const { texts, links } = dataJson;
  const [showInfo, setShowInfo] = useAsyncState(false);
  const onOpen = useCallback(() => setShowInfo(true), [setShowInfo]);
  const onClose = useCallback(() => setShowInfo(false), [setShowInfo]);

  return (
    <>
      <Container>
        <Wrapper>
          <Section>
            {texts.map((text) => (
              <Text key={text}>{text}</Text>
            ))}
          </Section>
          <Section>
            {links.map(({ id, type, name, value }) => (
              <Fragment key={id}>
                {type === 'link' && (
                  <Link href={value} target="_blank" rel="noopener noreferrer">
                    {name}
                  </Link>
                )}
                {type === 'button' && (
                  <Button type="button" onClick={value === 'popup' ? onOpen : undefined}>
                    {name}
                  </Button>
                )}
              </Fragment>
            ))}
          </Section>
        </Wrapper>
      </Container>
      {showInfo && (
        <Portal onClose={onClose}>
          <Popup />
        </Portal>
      )}
    </>
  );
};

export default Footer;
