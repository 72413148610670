/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback, useMemo, Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Icon from '@/assets/svg/Plus';

import { Container, Item, Title, Content, CollapsedBox, Text, Link, Ul, Li, Button } from './styles';

const Popup = () => {
  const { dataJson } = useStaticQuery(graphql`
    query {
      dataJson(jsonId: { eq: "footer" }) {
        popup {
          links {
            title
            items {
              name
              value
            }
          }
          imprint {
            title
            address
            email {
              name
              value
            }
            members {
              position
              name
            }
          }
          privacy {
            title
            items {
              type
              value
            }
          }
        }
      }
    }
  `);
  const { links, imprint, privacy } = dataJson.popup;
  const [isOpened, setOpened] = useState(false);
  const onClick = useCallback(() => setOpened(($) => !$), []);

  const privacyContent = useMemo(
    () =>
      privacy.items.map(({ type, value }, i) => (
        <Fragment key={i}>
          {type === 'text' && (
            <Text $mb>
              {value.map((text) => (
                <Fragment key={text}>
                  {text}
                  <br />
                </Fragment>
              ))}
            </Text>
          )}
          {type === 'list' && (
            <Ul>
              {value.map((item) => (
                <Li key={item}>{item}</Li>
              ))}
            </Ul>
          )}
        </Fragment>
      )),
    [privacy.items]
  );

  return (
    <Container>
      <Item>
        <Title>{links.title}</Title>
        <Content>
          {links.items.map(({ name, value }) => (
            <Link key={value} href={value} target="_blank" rel="noopener noreferrer">
              {name}
            </Link>
          ))}
        </Content>
      </Item>
      <Item>
        <Title>{imprint.title}</Title>
        <Content>
          <Text $mb>
            {imprint.address.map((text) => (
              <Fragment key={text}>
                {text}
                <br />
              </Fragment>
            ))}
          </Text>
          <Link href={imprint.email.value} target="_blank" rel="noopener noreferrer" $small>
            {imprint.email.name}
          </Link>
          {imprint.members.map(({ position, name }, i, arr) => (
            <Fragment key={name}>
              <Text>{position}</Text>
              <Text $mb={i < arr.length - 1}>{name}</Text>
            </Fragment>
          ))}
        </Content>
      </Item>
      <Item>
        <Button type="button" onClick={onClick} $isOpened={isOpened}>
          <span>{privacy.title}</span>
          <Icon />
        </Button>
        <CollapsedBox $isOpened={isOpened}>
          <Content>{privacyContent}</Content>
        </CollapsedBox>
      </Item>
    </Container>
  );
};

export default Popup;
