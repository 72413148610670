import React from 'react';
import PropTypes from 'prop-types';

import GlobalStyles from './GlobalStyles';
import Header from './Header';
import Footer from './Footer';
import { Main } from './styles';

const Layout = ({ location, children }) => (
  <>
    <GlobalStyles />
    <Header hash={location.hash} />
    <Main>{children}</Main>
    <Footer />
  </>
);

Layout.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export default Layout;
