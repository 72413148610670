import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 100%;
  padding: 0 0 0 20px;
  @media (max-width: 991px) {
    flex-direction: column;
    gap: 30px;
    height: auto;
    max-height: none;
    padding: 0 0 50px 10px;
  }
`;

export const Item = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 25%;
  &:last-of-type {
    width: 50%;
  }
  @media (max-width: 991px) {
    flex: 0 1 auto;
    width: 100%;
    &:last-of-type {
      width: 100%;
    }
  }
`;

export const Title = styled.h1`
  border-bottom: 1px solid var(--color-white);
  font: var(--font12);
  letter-spacing: 0.3px;
  color: var(--color-white);
  text-transform: uppercase;
  padding: 8px 0;
  margin-right: 20px;
  @media (max-width: 991px) {
    font: var(--font13);
    letter-spacing: 0.33px;
    margin-right: 10px;
  }
`;

export const Content = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 15px 20px 0 0;
  @media (max-width: 991px) {
    overflow: visible;
    padding: 8px 10px 0 0;
  }
`;

export const CollapsedBox = styled(motion.div).attrs(({ $isOpened }) => ({
  animate: {
    height: $isOpened ? 'auto' : 0,
    overflow: $isOpened && typeof window !== 'undefined' && window.innerWidth > 991 ? 'auto' : 'hidden',
  },
  transition: {
    height: { duration: 0.5 },
    overflow: { duration: 0, delay: $isOpened ? 0 : 0.5 },
  },
  initial: { height: 0, overflow: 'hidden' },
}))``;

export const Text = styled.p`
  width: 100%;
  font: var(--font18);
  letter-spacing: -0.13px;
  color: var(--color-white);
  margin-bottom: ${({ $mb }) => ($mb ? 1 : 0)}em;
  @media (max-width: 991px) {
    font: var(--font17);
    letter-spacing: -0.12px;
  }
`;

export const Link = styled.a`
  font: ${({ $small }) => ($small ? 'var(--font18)' : 'var(--font28)')};
  letter-spacing: ${({ $small }) => ($small ? '-0.13' : '-0.2')}px;
  color: var(--color-white);
  text-decoration: none;
  margin-bottom: ${({ $small }) => ($small ? 1 : 0)}em;
  &:active {
    opacity: 0.5;
  }
  @media (hover) {
    &:hover {
      opacity: 0.5;
    }
  }
  @media (max-width: 991px) {
    font: ${({ $small }) => ($small ? 'var(--font17)' : 'var(--font21)')};
    letter-spacing: ${({ $small }) => ($small ? '-0.12' : '-0.15')}px;
  }
`;

export const Ul = styled.ul`
  font: var(--font18);
  margin: 0 0 1em 1.1em;
  @media (max-width: 991px) {
    font: var(--font17);
  }
`;

export const Li = styled.li`
  font: var(--font18);
  letter-spacing: -0.13px;
  color: var(--color-white);
  @media (max-width: 991px) {
    font: var(--font17);
    letter-spacing: -0.12px;
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: calc(100% - 20px);
  border-bottom: 1px solid var(--color-white);
  font: var(--font12);
  letter-spacing: 0.3px;
  color: var(--color-white);
  text-align: left;
  text-transform: uppercase;
  padding: 8px 0;
  & > svg {
    transition: transform 0.5s;
    transform: rotate(${({ $isOpened }) => ($isOpened ? 45 : 0)}deg);
  }
  @media (max-width: 991px) {
    width: calc(100% - 10px);
    font: var(--font13);
    letter-spacing: 0.33px;
  }
`;
