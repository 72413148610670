import React, { useRef, useState, useCallback, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import Icon from '@/assets/svg/Plus';

import { Container, Button, Wrapper } from './styles';

const Portal = ({ onClose, children }) => {
  const ref = useRef(typeof document !== `undefined` ? document.createElement('div') : null);
  const [isOpened, setOpened] = useState(true);
  const onClick = useCallback(() => setOpened(false), []);
  const onAnimationComplete = useCallback(
    ({ opacity }) => {
      if (opacity === 0) onClose();
    },
    [onClose]
  );

  useLayoutEffect(() => {
    const html = document.querySelector('html');
    const portal = typeof document !== `undefined` ? document.getElementById('portal') : null;
    const elem = ref.current;

    if (html && portal && elem) {
      html.style.overflow = 'hidden';
      portal.appendChild(elem);

      return () => {
        portal.removeChild(elem);
        html.style.overflow = 'auto';
      };
    }

    return () => null;
  }, []);

  return ref.current
    ? createPortal(
        <Container $isOpened={isOpened} onAnimationComplete={onAnimationComplete}>
          <Button type="button" onClick={onClick}>
            <Icon />
          </Button>
          <Wrapper>{children}</Wrapper>
        </Container>,
        ref.current
      )
    : null;
};

Portal.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default Portal;
