import React, { useState, useCallback } from 'react';
import { pathOr } from 'ramda';
import PropTypes from 'prop-types';

import { HEADER_ITEMS } from '@/utils/consts';
import { isTouchDevice } from '@/utils';

import { HASH_MAP } from './consts';
import { useScroll } from './hooks';
import { Container, Thumb, Button } from './styles';

const Header = ({ hash }) => {
  const activeHashIndex = pathOr(null, [hash, 0], HASH_MAP);
  const [hoverIndex, setHoverIndex] = useState(null);
  const onHoverStart = useCallback(({ target }) => {
    if (!isTouchDevice()) setHoverIndex(Number(target.value));
  }, []);
  const onHoverEnd = useCallback(() => {
    if (!isTouchDevice()) setHoverIndex(null);
  }, []);
  const activeIndex = hoverIndex ?? activeHashIndex;

  const scrollIntoView = useCallback((elemId, behavior = 'smooth') => {
    if (typeof window === 'undefined') return;

    const elem = document.querySelector(`#${elemId}`);

    elem?.scrollIntoView({ behavior, block: 'start' });
  }, []);
  const handleClick = useCallback(
    ({ currentTarget }) => {
      if (currentTarget.dataset.id) scrollIntoView(currentTarget.dataset.id);
    },
    [scrollIntoView]
  );

  useScroll(hash, scrollIntoView);

  return (
    <Container>
      <Thumb $id={activeIndex} />
      {HEADER_ITEMS.map(({ index, id, label, ratio }) => (
        <Button
          key={id}
          type="button"
          value={index}
          data-id={id}
          onClick={handleClick}
          onHoverStart={onHoverStart}
          onHoverEnd={onHoverEnd}
          $ratio={ratio}
        >
          {label}
        </Button>
      ))}
    </Container>
  );
};

Header.propTypes = { hash: PropTypes.string.isRequired };

export default Header;
