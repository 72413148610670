import { createGlobalStyle } from 'styled-components';

import neueHelveticaWoff from '@/assets/fonts/NeueHelvetica.woff';
import neueHelveticaWoff2 from '@/assets/fonts/NeueHelvetica.woff2';

export default createGlobalStyle`
  @font-face {
    font-family: "NeueHelvetica";
    font-stretch: normal;
    font-style: normal;
    font-display: swap;
    src: url(${neueHelveticaWoff}) format("woff"), url(${neueHelveticaWoff2}) format("woff2");
  }
  html {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 350px;
  }
  body, #___gatsby, #gatsby-focus-wrapper  {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: transparent;
    -webkit-overflow-scrolling: touch;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
  }
  button {
    appearance: none;
    border: none;
    border-radius: 0;
    background: none;
    outline: none;
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
    }
  }
  input, textarea {
    appearance: none;
    border: none;
    border-radius: 0;
    background: none;
    outline: none;
    &:disabled {
      cursor: not-allowed;
    }
  }
  :root {
    --color-white: #fff;
    --color-black: #000;
    --color-greyAC: #acacac;
    --color-greyDE: #dedede;
    --color-greyF7: #f7f7f7;
    --color-greyF7-rgb: 247, 247, 247;
    --color-grey46-rgb: 70, 70, 70;
    --color-yellow: #F4FF67;
    --color-red: #f00;
    --color-red-rgb: 255, 0, 0;

    --font106: 106px/116px "NeueHelvetica", sans-serif;
    --font42: 42px/46px "NeueHelvetica", sans-serif;
    --font28: 28px/32px "NeueHelvetica", sans-serif;
    --font21: 21px/25px "NeueHelvetica", sans-serif;
    --font18: 18px/20px "NeueHelvetica", sans-serif;
    --font17: 17px/20px "NeueHelvetica", sans-serif;
    --font14: 14px/17px "NeueHelvetica", sans-serif;
    --font13: 13px/16px "NeueHelvetica", sans-serif;
    --font12: 12px/15px "NeueHelvetica", sans-serif;

    --vh: 1dvh;

    --header-height: 38px;
    --footer-height: 43px;

    @supports not (height: 1dvh) {
      --vh: 1vh;
    }
    @media (max-width: 991px) {
      --footer-height: 102px;
    }
  }
`;
