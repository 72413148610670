import { useRef, useEffect } from 'react';
import { navigate } from 'gatsby';

import { debounce } from '@/utils';
import { ID_PREFIX } from '@/utils/consts';

import { HASH_MAP } from './consts';

export const useScroll = (hash, scrollIntoView) => {
  const hashRef = useRef(hash);
  useEffect(() => {
    if (typeof window === 'undefined') return () => null;

    const sections = document.querySelectorAll('section');
    const handler = () => {
      const offset = window.innerHeight * 0.33;
      sections.forEach((section, i, arr) => {
        if (!(window.scrollY + offset >= section.offsetTop && window.scrollY + offset < (arr[i + 1]?.offsetTop || Infinity)))
          return;

        const sectionHash = section.id.replace(ID_PREFIX, '#');

        if (section.id && hashRef.current !== sectionHash) {
          navigate(sectionHash);
          return;
        }
        if (!section.id && hashRef.current !== '#welcome') navigate('#welcome');
      });
    };
    const onScroll = debounce(handler, 50);

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined' && HASH_MAP[hash]) {
      const timer = setTimeout(() => {
        scrollIntoView(HASH_MAP[hash][1]);
      }, 150);

      return () => clearTimeout(timer);
    }

    return () => null;
  }, [scrollIntoView]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    hashRef.current = hash;
  }, [hash]);
};
